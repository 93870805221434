<template>
  <b-row>
    <b-col cols="6" class="mx-auto">
      <div class="text-center" v-if="isLoading">Loading...</div>
      <b-card title="Add New Department" no-body v-if="!isLoading">
        <b-card-header class="d-flex align-items-right">
          <h5 class="mb-0">
            Edit Support Department <br /><span class="text-muted">{{
              existingDepartmetnName
            }}</span>
          </h5>
          <b-link to="/tickets/departments" class="btn btn-sm btn-secondary"
            ><feather-icon icon="ArrowLeftIcon" /> View All Departments</b-link
          >
        </b-card-header>
        <b-card-body>
          <div v-if="message" class="alert alert-danger p-1" id="message">{{ message }}</div>
          <div v-if="success" class="alert alert-success p-1" id="message">{{ success }}</div>
          <b-form-group label="Department Name">
            <b-form-input v-model="name" />
          </b-form-group>
          <b-form-group label="Department Email Address">
            <b-form-input v-model="email" />
          </b-form-group>
          <b-form-group label="Status">
            <b-form-select v-model="status" :options="statusOptions" />
          </b-form-group>
          <b-button
            @click="saveDepartment"
            variant="primary"
            :disabled="!name || !email || isSaving"
            >Submit</b-button
          >
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
} from "bootstrap-vue";
import axios from "axios";
export default {
  name: "EditDepartment",

  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCardHeader,
    BCardBody,
    BLink,
  },
  data() {
    return {
      existingDepartmetnName: "",
      id: "",
      name: "",
      email: "",
      status: true,
      statusOptions: [
        { value: true, text: "Active" },
        { value: false, text: "Disabled" },
      ],
      isSaving: false,
      isLoading: false,
      message: "",
      success: "",
    };
  },
  created() {
    this.loadDepartment();
  },
  methods: {
    loadDepartment: async function () {
      try {
        this.isLoading = true;
        const id = this.$route.params.id;
        const { data } = await axios.get(`/support/departments/${id}`);
        this.name = data.data.name;
        this.email = data.data.email;
        this.status = data.data.status;
        this.id = data.data._id;
      } catch (e) {
        this.message = e.response.data.error.message || e.message;
      } finally {
        this.isLoading = false;
      }
    },
    saveDepartment: async function () {
      try {
        this.isSaving = true;
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("status", this.status);
        const { data } = await axios.put(`/support/departments/${this.id}`, formData);
        this.success = data.message;
      } catch (e) {
        this.message = e.response.data.error.message || e.message;
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
